import React from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.article`
  width: 100%;
  padding: 30px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
`;

const Title = styled.h2`
  margin: 0 0 10px;
  color: #13022c;
  font-family: Montserrat;
`;

const Section = styled.section`
  margin-bottom: 15px;
  font-weight: 300;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  margin: 0 0 10px;
  border: 1px solid #cccccc;
`;

export default function Project({ project }) {
  return (
    <Wrapper>
      <Title>
        <a href={project.url} target="_blank" rel="noreferrer">
          {project.name}
        </a>
      </Title>

      <Section>
        <div>
          <strong>Languages:</strong> {project.languages}
        </div>
        <div>
          <strong>Frameworks/Libraries:</strong> {project.libraries}
        </div>
      </Section>

      <Section>{project.description}</Section>

      <Image src={project.image} alt={project.name} />
    </Wrapper>
  );
}
