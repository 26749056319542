import React from 'react';
import styled from '@emotion/styled';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Bio from '../components/Bio';
import { Container } from '../components/styled-components';
import Project from '../components/Project';
import projects from '../data/projects';

const ProjectsList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  width: 100%;
  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }
`;

export default function Projects() {
  return (
    <Layout>
      <SEO title="Projects" />

      <Container>
        <Bio />

        <ProjectsList>
          {[...projects].reverse().map((project) => (
            <Project project={project} id={project.id} key={project.id} />
          ))}
        </ProjectsList>
      </Container>
    </Layout>
  );
}
