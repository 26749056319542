import pokedex from '../../content/assets/projects/pokedex.png';
import todo from '../../content/assets/projects/todo.png';
import news from '../../content/assets/projects/news.png';

const projects = [
  {
    id: 1,
    name: 'Pokédex',
    url: 'https://pokedex.drihu.com/',
    languages: 'JavaScript, CSS',
    libraries: 'none',
    image: pokedex,
    description: 'A simple pokédex built with pure JavaScript and CSS',
  },
  {
    id: 2,
    name: 'ToDo',
    url: 'https://todo.drihu.com/',
    languages: 'JavaScript, CSS',
    libraries: 'React',
    image: todo,
    description: 'A small and compact React app to create ToDo tasks',
  },
  {
    id: 3,
    name: 'News Site',
    url: 'http://news.drihu.com/',
    languages: 'Ruby, CSS',
    libraries: 'Sinatra',
    image: news,
    description: 'All news from Y Combinator in a personal News Site.',
  },
];

export default projects;
